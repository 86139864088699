<template>
  <component
    :is="task.taskType"
    v-if="isReady"
    ref="subCard"
    v-bind="componentAttrs"
    @submit-response="emitSubmitResponse"
    @update:inputData="emitInputDataChange"
    @update:taskAttachments="emitTaskAttachmentsChange"
  />
</template>

<script>
import MultipleChoiceQuestion from './MultipleChoiceQuestion';
import CalculationQuestion from './CalculationQuestion';
import DynamicQuestion from './DynamicQuestion';
import WatchVideoTask from './WatchVideoTask';
import DataDrivenTask from './DataDrivenTask';
import Task from '@/tasks/models/Task';
import TaskResponse from '@/tasks/models/TaskResponse';
import TaskState from '@/task-states/models/TaskState';
import {computed, onMounted, ref, shallowRef, watch} from '@vue/composition-api';
import {useEventBus} from '@vueuse/core';
import {provideRandomNumberBackground} from '@/tasks/components/composables/useRandomNumberBackgroundStyle';

export default {
  name: 'LegacyTask',
  components: {
    WatchVideoTask,
    DynamicQuestion,
    CalculationQuestion,
    MultipleChoiceQuestion,
    DataDrivenTask,
  },
  setup() {
    const {on: onSubmitResponse, emit: emitSubmitResponse} = useEventBus(Symbol('submitResponse'));
    const {on: onInputDataChange, emit: emitInputDataChange} = useEventBus(Symbol('inputData'));
    const {on: onTaskAttachmentsChange, emit: emitTaskAttachmentsChange} = useEventBus(
      Symbol('taskAttachments')
    );

    const subCard = ref();
    const taskId = ref(0);
    const taskStateId = ref(0);

    const isSubmitting = ref(false);
    const enableClearResponse = ref(false);

    const {showRandomVariables} = provideRandomNumberBackground();

    const task = computed(() => Task.fullQuery().find(taskId.value));
    const taskState = computed(() => TaskState.fullQuery().find(taskStateId.value));
    const response = shallowRef(null);
    const isReady = computed(() => {
      return task.value !== null && (!task.value.isRandomizable() || taskState.value !== null);
    });

    const componentAttrs = computed(() => {
      return {
        task: task.value,
        isSubmitting: isSubmitting.value,
        // FIXME: this will not be the same generally
        allowEditing: !isSubmitting.value,
        taskState: taskState.value,
        showRandomVariables: showRandomVariables.value,
      };
    });

    watch(subCard, syncResponseOnSubCard);
    watch(response, syncResponseOnSubCard);

    function syncResponseOnSubCard() {
      const r = response.value;
      if (r !== null) {
        subCard.value?.setResponse(r);
      } else if (enableClearResponse.value) {
        subCard.value?.clearResponse();
      }
    }

    /*
     * Exposed functions for Inertia front-end
     */
    async function setTaskAndState(task, taskState = null) {
      await Promise.all([Task.deleteAll(), TaskState.deleteAll()]);

      if (task) {
        taskId.value = task.id;
        await Task.insert({
          data: JSON.parse(JSON.stringify(task)),
        });
      }
      if (taskState) {
        taskStateId.value = taskState.id;
        await TaskState.insert({
          data: JSON.parse(JSON.stringify(taskState)),
        });
      }
    }

    function setResponse(newResponse, clearResponseWhenNotSet) {
      enableClearResponse.value = clearResponseWhenNotSet;

      if (newResponse) {
        response.value = new TaskResponse(JSON.parse(JSON.stringify(newResponse)));
      } else {
        response.value = null;
      }
    }

    function setIsSubmitting(submitting) {
      isSubmitting.value = submitting;
    }

    function triggerSubmitResponse() {
      subCard.value?.submitResponse();
    }

    function setShowRandomVariables(show) {
      showRandomVariables.value = show;
    }

    function setupWindow() {
      window.stembleLegacyTaskComponent = {
        setIsSubmitting,
        setTaskAndState,
        setResponse,
        triggerSubmitResponse,
        onSubmitResponse,
        onInputDataChange,
        onTaskAttachmentsChange,
        setShowRandomVariables,
      };
    }

    onMounted(setupWindow);

    return {
      task,
      emitSubmitResponse: (payload) => emitSubmitResponse(JSON.parse(JSON.stringify(payload))),
      emitInputDataChange: (payload) => emitInputDataChange(JSON.parse(JSON.stringify(payload))),
      subCard,
      emitTaskAttachmentsChange,
      isReady,
      componentAttrs,
    };
  },
};
</script>

<style lang="sass">
.submitButton
  background-color: #d83d0e !important
  border: 0px !important

.theme--light.v-label
  color: black !important

.is-selected
  background-color: #B9C9D8 !important
  color: white !important

.is-active-reload
  color: #FFAC6E !important

.text-btn::before
  color: transparent !important

.text-btn:hover
  color: #d83d0e !important

.text-btn
  margin-right: -18px

.no-background-hover::before
  background-color: transparent !important

.v-card__text
  line-height: 28px

.progressContainer
  width: 60%
  margin: 0 auto

.progress
  margin: 0
  border-radius: 5rem
  overflow: hidden
  border: none
  background-color: red !important

@media only screen and (min-width: 768px)
  .quiz_card_height
    height: 100%
  .quiz_button_width
    width: 50%

@media only screen and (max-width: 768px)
  .quiz_card_height
    height: 100%
  .quiz_button_width
    width: 100%
</style>
